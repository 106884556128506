// This file generates classes based on the $variants and applies support for them for all $components
// The classes are used as a replacement for the color attribute, which is not compatible with Angular m3
// examples: primary-color, secondary-color, error-color

@use '@angular/material' as mat;
@use 'sass:meta';

$variants: 'primary', 'secondary', 'error';
$components: (
  'badge',
  'button',
  'checkbox',
  'chips',
  'datepicker',
  'form-field',
  'fab',
  'icon',
  'option',
  'progress-bar',
  'progress-spinner',
  'pseudo-checkbox',
  'radio',
  'select',
  'slide-toggle',
  'slider',
  'stepper',
  'tabs'
);
// some contrast colors do not properly update when using a dynamic theme in m3, so we override them by connecting them to a css variable that will be changed by typescript whenever the theme is updated
$contrast-overrides: (
  '--mat-full-pseudo-checkbox-selected-checkmark-color',
  '--mdc-linear-progress-track-color',
  '--mdc-slider-label-label-text-color',
  '--mdc-filled-button-label-text-color',
  '--mat-datepicker-calendar-date-selected-state-text-color'
);

@mixin mat-component-variants($theme) {
  // creates a class for each variant/color that can be manually set on components (primary-color, secondary-color, error-color)
  @each $variant in $variants {
    .#{$variant}-color {
      // Loop applies the colors of the theme variant to all components.
      // This is needed for angular (material) to understand what colors need to be set when a component has 1 of the color classes
      @each $component in $components {
        @if (is-variant-supported($variant, $component)) {
          @include meta.apply(
            meta.get-mixin('#{$component}-color', mat),
            $theme,
            $color-variant: $variant
          );
        }
      }
      // set contrast overrides within the class, based on variant theme palette
      & {
        @each $contrast-override in $contrast-overrides {
          #{$contrast-override}: var(--#{$variant}-contrast);
        }
      }
    }
  }
  // set contrast overrides globally, based on primary theme palette
  & {
    @each $contrast-override in $contrast-overrides {
      #{$contrast-override}: var(--primary-contrast);
    }
  }
}

@function is-variant-supported($variant, $component) {
  @if ($variant == 'error' and $component == 'fab') {
    @return false;
  }

  @return true;
}
