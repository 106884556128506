html,
body {
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
}
.activate-dialog {
  max-width: calc(100vw - 24px) !important;
  .loading-wrapper {
    max-height: 600px;
    max-width: 200px;
  }
  .mat-dialog-container {
    padding: 0;
  }
}

.spinner-button {
  mat-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
}

.loading-wrapper {
  height: 100vh;
  width: 100vw;
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.hidden-file-input-button {
  visibility: hidden;
  width: 0;
}

.wrapper-bg {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--primary);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 22px 12px;

  .header-wrapper {
    margin: auto;
    max-width: 600px;
    display: flex;
    @media screen and (max-width: 625px) {
      flex-direction: column;
      align-items: center;
    }
    .header-image-wrapper {
      display: flex;
      .header-img {
        width: 120px;
        height: 108px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: white;
        border-radius: 16px;
        border: white solid 10px;
      }
    }
    .header-text-button-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @media screen and (max-width: 625px) {
        align-items: center;
      }
      .header-text {
        color: white;
        font-family: Viga;
        font-weight: 500;
        margin-top: 10px;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 450px;
        @media screen and (max-width: 485px) {
          max-width: 350px;
        }
        @media screen and (max-width: 625px) {
          margin: 10px 0 0 0;
        }
      }
      .header-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // TODO
        // @media screen and (max-width: 625px) {
        //   display: none;
        // }
        .header-button {
          display: flex;
          width: fit-content;
          .mat-icon {
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }
}

.voucher-group-card-room {
  height: 110px;
  background-color: unset !important;
  border: none !important;
}

.voucher-group-card,
.voucher-group-card-room {
  width: 100%;
  background-color: #f2f0e7;
  border-radius: 16px;
  border: solid 4px white;
  display: flex;
  flex-direction: row;
  margin: 12px 0 16px;

  .avatar {
    width: 120px;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .avatar.large {
    width: 140px;
  }
  .avatar.placeholder {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    font-weight: 550;
    font-size: 50px;
    color: var(--primary-contrast);
    background-color: var(--primary);
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  .card-content {
    margin: 8px 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .title-slogan {
    margin: 8px 0;
    .card-title {
      font-size: 16px;
      font-weight: 500;
      color: #1c1c18;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .slogan {
      margin-top: 8px;
      font-size: 14px;
      color: #1c1c18;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .voucher-value-wrapper {
    margin-top: auto;
    .voucher-value {
      font-weight: bold;
      font-size: 28px;
      color: var(--primary);
    }
    .coupon {
      font-weight: 500;
      font-size: 14px;
      margin: 4px 0;
    }
    .alt-coupon {
      color: var(--primary);
      font-weight: 500;
      margin: 4px 0;
    }
  }
}

.custom-tooltip {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 16px 0;
  cursor: pointer;
  width: fit-content;
}

.custom-tooltip::after {
  width: 180px;
  padding: 10px;
  background-color: #404942;
  border: 1px solid #707070;
  border-radius: 8px;
  content: 'U kunt uw pincode vinden in de door u ontvangen brief.';
  color: white;
  text-decoration: unset;
  position: absolute;
  margin: 10px 0 0 0;
  z-index: 10;
  display: block;
  font-weight: normal;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s, opacity 0.2s ease-out;
}
.custom-tooltip:hover::after,
.custom-tooltip:active::after,
.custom-tooltip:focus::after,
.custom-tooltip:focus-visible::after {
  opacity: 1;
  visibility: visible;
}

// Use this for testing accessibility
// * {
//   line-height: 1.5 !important;
//   letter-spacing: 0.12em !important;
//   word-spacing: 0.16em !important;
// }
// p {
//   margin-bottom: 2em !important;
// }
