@use '@angular/material' as mat;
@use 'mixins';
@use 'sass:map';
@use 'color-palettes/dynamic-theme.scss' as dynamic;

@include mat.core();

:root {
  --primary: #006239;
  --primary-contrast: #ffffff;
  --accent: #913a00;
  --warn: #b20c00;
  --warn-contrast: #ffffff;
  --master: #62c890;
}

$dynamic-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: dynamic.$dynamic-palette,
    ),
  )
);
body {
  @include mat.all-component-themes($dynamic-theme);
  @include mixins.mat-component-variants($dynamic-theme);
}
