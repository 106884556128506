@use 'sass:color';

:root {
  --primary-10: #ffffff,
  --primary-20: #ffffff,
  --primary-25: #ffffff,
  --primary-30: #ffffff,
  --primary-35: #ffffff,
  --primary-40: #ffffff,
  --primary-50: #ffffff,
  --primary-60: #ffffff,
  --primary-70: #ffffff,
  --primary-80: #ffffff,
  --primary-90: #ffffff,
  --primary-95: #ffffff,
  --primary-98: #ffffff,
  --primary-99: #ffffff,
  --primary-contrast: #ffffff,
  --secondary-10: #ffffff,
  --secondary-20: #ffffff,
  --secondary-25: #ffffff,
  --secondary-30: #ffffff,
  --secondary-35: #ffffff,
  --secondary-40: #ffffff,
  --secondary-50: #ffffff,
  --secondary-60: #ffffff,
  --secondary-70: #ffffff,
  --secondary-80: #ffffff,
  --secondary-90: #ffffff,
  --secondary-95: #ffffff,
  --secondary-98: #ffffff,
  --secondary-99: #ffffff,
  --secondary-contrast: #ffffff,
}

$dynamic-palette: (
  0: #000000,
  10: var(--primary-10),
  20: var(--primary-20),
  25: var(--primary-25),
  30: var(--primary-30),
  35: var(--primary-35),
  40: var(--primary-40),
  50: var(--primary-50),
  60: var(--primary-60),
  70: var(--primary-70),
  80: var(--primary-80),
  90: var(--primary-90),
  95: var(--primary-95),
  98: var(--primary-98),
  99: var(--primary-99),
  100: #ffffff,
  secondary: (
    0: #000000,
    10: var(--secondary-10),
    20: var(--secondary-20),
    25: var(--secondary-25),
    30: var(--secondary-30),
    35: var(--secondary-35),
    40: var(--secondary-40),
    50: var(--secondary-50),
    60: var(--secondary-60),
    70: var(--secondary-70),
    80: var(--secondary-80),
    90: var(--secondary-90),
    95: var(--secondary-95),
    98: var(--secondary-98),
    99: var(--secondary-99),
    100: #ffffff
  ),
  neutral: (
    0: #000000,
    10: #1c1b1f,
    20: #313034,
    25: #3c3b3f,
    30: #47464a,
    35: #535256,
    40: #5f5e62,
    50: #78767a,
    60: #929094,
    70: #adaaaf,
    80: #c8c5ca,
    90: #e5e1e6,
    95: #f3eff4,
    98: #fcf8fd,
    99: #fffbff,
    100: #ffffff,
    4: #0e0e11,
    6: #131316,
    12: #201f23,
    17: #2a292d,
    22: #353438,
    24: #39393c,
    87: #dcd9de,
    92: #eae7ec,
    94: #f0edf1,
    96: #f6f2f7,
  ),
  neutral-variant: (
    0: #000000,
    10: #1b1b23,
    20: #303038,
    25: #3b3b43,
    30: #46464f,
    35: #52515b,
    40: #5e5d67,
    50: #777680,
    60: #918f9a,
    70: #acaab4,
    80: #c7c5d0,
    90: #e4e1ec,
    95: #f2effa,
    98: #fcf8ff,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);
