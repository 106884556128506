@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

:root {
  --primary: #006239;
  --primary-contrast: #ffffff;
  --accent: #913a00;
  --warn: #b20c00;
  --warn-contrast: #ffffff;
  --master: #62c890;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// GENERATE THEME FROM HEX HERE: http://mcg.mbitson.com/
$primary: (
  50: #e0ece7,
  100: #b3d0c4,
  200: #80b19c,
  300: #4d9174,
  400: #267a57,
  500: #006239,
  600: #005a33,
  700: #00502c,
  800: #004624,
  900: #003417,
  A100: #6cff9c,
  A200: #39ff79,
  A400: #06ff57,
  A700: #00eb4c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$accent: (
  50: #f2e7e0,
  100: #dec4b3,
  200: #c89d80,
  300: #b2754d,
  400: #a25826,
  500: #913a00,
  600: #893400,
  700: #7e2c00,
  800: #742500,
  900: #621800,
  A100: #ffa392,
  A200: #ff795f,
  A400: #ff4e2c,
  A700: #ff3813,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$warn: (
  50: #f6e2e0,
  100: #e8b6b3,
  200: #d98680,
  300: #c9554d,
  400: #be3026,
  500: #b20c00,
  600: #ab0a00,
  700: #a20800,
  800: #990600,
  900: #8a0300,
  A100: #ffb6b6,
  A200: #ff8383,
  A400: #ff5050,
  A700: #ff3636,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);
$primary: mat.define-palette($primary);
$accent: mat.define-palette($accent, 500, 400, 700);
// The warn palette is optional (defaults to red).
// $warn: mat.define-palette(mat.$red-palette);
$warn: mat.define-palette($warn);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);
